import { useTranslation } from 'react-i18next';
import { generateLink } from '../utils/i18n';
const Terms = () => {
    const { t } = useTranslation();
    return <div className='content'>
        <ul className="tabs">
            <li className="active" data-tab="terms">
                <a href={generateLink('/terms-and-conditions')}>{t('general.terms_and_conditions_tab')}</a>
            </li>
            <li data-tab="privacy">
                <a href={generateLink('/privacy-policy')}>{t('general.privacy_policy_tab')}</a>
            </li>
            <li data-tab="offerta">
                <a href={generateLink('/offerta')}>{t('general.offerta_tab')}</a>
            </li>
            <li data-tab="rules">
                <a href={generateLink('/rules-of-use')}>{t('general.rules_of_use_tab')}</a>
            </li>
        </ul>
        <div class="container">
            <div class="text_privacy" dangerouslySetInnerHTML={{ __html: t('privacy.text_terms_and_conditions') }} />
        </div>
    </div>
}

export default Terms