import React from 'react'
import { createBrowserRouter } from "react-router-dom"
import LayoutPrivate from '../components/Layout/LayoutPrivate'
import LayoutAuth from '../components/Layout/LayoutAuth'
import Home from '../pages/Home'
import Login from '../pages/Login'
import Verify from '../pages/Verify'
import Signup from '../pages/Signup'
import Profile from '../pages/Profile'
import Subscription from '../pages/Subscription'
import Friends from '../pages/Friends'
import SavedVideos from '../pages/SavedVideos'
import PopupsPage from '../pages/PopupsPage'
import SignupPhoto from '../pages/SignupPhoto'
import UsersList from '../pages/UsersList'
import FriendCall from '../pages/FriendCall'
import ResetPassword from '../pages/ResetPassword'
import ForgotPassword from '../pages/ForgotPassword'

import LayoutPublicTerms from '../components/Layout/LayoutPublicTerms'
import Terms from '../pages/Terms'
import Privacy from '../pages/Privacy'
import Offerta from '../pages/Offerta'
import Rules from '../pages/Rules'



const routes = [
    { path: '/:lang?', element: <LayoutPrivate component={Home} /> },
    { path: '/:lang?/profile', element: <LayoutPrivate component={Profile} /> },
    { path: '/:lang?/subscription', element: <LayoutPrivate component={Subscription} /> },
    { path: '/:lang?/friends', element: <LayoutPrivate component={Friends} /> },
    { path: '/:lang?/videos', element: <LayoutPrivate component={SavedVideos} /> },
    { path: '/:lang?/popups', element: <LayoutPrivate component={PopupsPage} /> },
    { path: '/:lang?/users-list', element: <LayoutPrivate component={UsersList} /> },
    { path: '/:lang?/friend-call', element: <LayoutPrivate component={FriendCall} /> },
    { path: '/:lang?/login', element: <LayoutAuth component={Login} /> },
    { path: '/:lang?/signup', element: <LayoutAuth component={Signup} /> },
    { path: '/:lang?/verify', element: <LayoutPrivate component={Verify} /> },
    { path: '/:lang?/signup-photo', element: <LayoutPrivate component={SignupPhoto} /> },
    { path: '/:lang?/password-reset', element: <LayoutAuth component={ResetPassword} /> },
    { path: '/:lang?/forgot-password', element: <LayoutAuth component={ForgotPassword} /> },

    { path: '/:lang?/terms-and-conditions', element: <LayoutPublicTerms component={Terms} /> },
    { path: '/:lang?/privacy-policy', element: <LayoutPublicTerms component={Privacy} /> },
    { path: '/:lang?/offerta', element: <LayoutPublicTerms component={Offerta} /> },
    { path: '/:lang?/rules-of-use', element: <LayoutPublicTerms component={Rules} /> },
];

export const router = createBrowserRouter(routes);
